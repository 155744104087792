import * as picassoLd from "@reifyhealth/picasso-launchdarkly";
import { pick } from "lodash";

const FINANCIALS_FLAG_NAMES = ["site-financials-unscheduled-visits"] as const;

export type FinancialsFlagSet = Partial<
  Record<(typeof FINANCIALS_FLAG_NAMES)[number], boolean>
>;

/**
 * Returns an object containing all Financials flag values.
 *
 * To add a new flag, update the `FINANCIALS_FLAG_NAMES` constant.
 */
export const useFlags = (): FinancialsFlagSet => {
  const allFlags = picassoLd.useFlags("none");
  return pick(allFlags, FINANCIALS_FLAG_NAMES);
};
