import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  FormInstance,
  Card,
  Select,
  Space,
} from "@reifyhealth/picasso-pkg";
import { useUserSiteTrialsQuery } from "@service/generated";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@linaria/core";
import sortBy from "lodash/sortBy";
import PercentageInput from "@components/inputs/PercentageInput";

const budgetForm = css`
  width: var(--size-24) !important;
  margin-left: 4px;
  margin-right: 4px;
`;

interface CreateBudgetFormShape {
  budgetName: string;
  trialId: string;
  protocolVersionId: string;
  startDate: string;
  endDate: string;
  holdbackPercentage: string;
  overheadPercentage: string;
}

interface AddCreateBudgetFormProps {
  siteId: string;
  form: FormInstance<Partial<CreateBudgetFormShape>>;
  isBudgetVersionCreation: boolean;
}

const { TextArea } = Input;

export const BudgetDetailsForm = ({
  siteId,
  form,
  isBudgetVersionCreation,
}: AddCreateBudgetFormProps) => {
  const [search] = useSearchParams();
  const [siteTrialId, setSiteTrialId] = useState<string | undefined>(undefined);
  const [initialBudgetName, setInitialBudgetName] = useState<boolean>(false);

  useEffect(() => {
    if (isBudgetVersionCreation) {
      form.setFields([
        { value: search.get("budget-name"), name: "budgetName" },
        { value: search.get("trial-id"), name: "trialId" },
      ]);

      setSiteTrialId(search.get("trial-id") || "");
    }
  }, [search, form, isBudgetVersionCreation]);

  const {
    data: userSiteTrialsWithBudget,
    isLoading,
    isFetching,
  } = useUserSiteTrialsQuery(
    {
      filter: { siteId: siteId, hasBudget: isBudgetVersionCreation },
    },
    {
      queryKey: "userSiteTrialWithBudgetsOnly",
    }
  );

  const siteTrialOptions =
    sortBy(
      userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges
        .filter((trial) => trial.node.protocol !== null)
        .map((edge) => ({
          value: edge.node.id,
          label: edge.node.name,
        })),
      "label"
    ) || [];

  const siteTrialProtocolVersionOptions =
    sortBy(
      userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges
        .filter((edge) => edge.node.id === siteTrialId)
        .filter((edge) => edge.node.protocol)
        .flatMap((edge) => edge.node.protocol?.versions)
        .filter((version) => version?.status === "PUBLISHED")
        .map((protocol) => ({
          value: protocol?.id ?? "",
          label: protocol?.name ?? "",
        }))
        .filter((entry) => entry.label || entry.value),
      "label"
    ) || [];

  const paymentTermOptions = [15, 30, 45, 60, 90].map((day) => ({
    value: day,
    label: `Net ${day}`,
  }));

  const handleFormChange = (
    _changed: Partial<CreateBudgetFormShape>,
    all: Partial<CreateBudgetFormShape>
  ) => {
    const { trialId, budgetName } = all;

    if (trialId && !budgetName && !initialBudgetName) {
      form.setFieldsValue({
        budgetName: siteTrialOptions.find((option) => option.value === trialId)
          ?.label,
      });

      setInitialBudgetName(true);
    }

    setSiteTrialId(trialId);
  };

  return (
    <Card bordered>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        initialValues={{ paymentTermDays: 30 }}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col>
            <Space>
              <Form.Item
                label="Trial Name:"
                name="trialId"
                rules={[
                  {
                    required: true,
                    message: "Please input a trial name",
                  },
                ]}
                required
              >
                <Select
                  data-testid="site-trial-select"
                  loading={isFetching || isLoading}
                  disabled={isFetching || isLoading || isBudgetVersionCreation}
                  className={budgetForm}
                  placeholder="Choose a Trial"
                  options={siteTrialOptions}
                />
              </Form.Item>
              <Form.Item
                label="Version:"
                name="protocolVersionId"
                rules={[
                  {
                    required: true,
                    message: "Please input a protocol version",
                  },
                ]}
                dependencies={["trialId"]}
                required
              >
                <Select
                  data-testid="site-trial-protocol-select"
                  disabled={!siteTrialId}
                  className={budgetForm}
                  placeholder="Choose a version"
                  options={siteTrialProtocolVersionOptions}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row>
          <Form.Item
            label="Budget Name"
            name="budgetName"
            required
            style={{ flexDirection: "column", alignItems: "flex-start" }}
            rules={[{ required: true, message: "Please input a budget name" }]}
          >
            <Input
              className={budgetForm}
              data-testid="budget-name-input"
              placeholder="Enter a name"
              disabled={isBudgetVersionCreation}
            />
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Col>
            <Space>
              <Form.Item
                name="startDate"
                label="Effective Dates:"
                rules={[
                  {
                    required: true,
                    message: "Please input a start date",
                  },
                ]}
                style={{ flexDirection: "column", alignItems: "flex-start" }}
                required
              >
                <DatePicker
                  data-testid="effective-dates-datepicker"
                  className={budgetForm}
                  onChange={(date) => {
                    form.setFields([{ value: date, name: "startDate" }]);

                    // used only for UI elements (manual validation fires off proper error states in form)
                    form.validateFields(["startDate"]).catch(() => null);
                  }}
                  placeholder="Start Date"
                />
              </Form.Item>
              <Form.Item
                name="endDate"
                label=" "
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <DatePicker
                  data-testid="end-date-datepicker"
                  className={budgetForm}
                  onChange={(date) => {
                    form.setFields([{ value: date, name: "endDate" }]);
                  }}
                  placeholder="End Date (optional)"
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <Space>
              <Form.Item
                label="Payment Terms:"
                name="paymentTermDays"
                required
                style={{ flexDirection: "column", alignItems: "flex-start" }}
                rules={[
                  { required: true, message: "Please select a payment term" },
                ]}
              >
                <Select
                  data-testid="payment-terms-select"
                  className={budgetForm}
                  placeholder="Choose a payment term"
                  options={paymentTermOptions}
                  disabled={isBudgetVersionCreation}
                />
              </Form.Item>
              <Form.Item
                label="Holdback:"
                name="holdbackPercentage"
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PercentageInput
                  className={budgetForm}
                  data-testid="budget-holdback-input"
                />
              </Form.Item>
              <Form.Item
                label="Overhead:"
                name="overheadPercentage"
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <PercentageInput
                  className={budgetForm}
                  data-testid="budget-overhead-input"
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        {isBudgetVersionCreation && (
          <Row style={{ marginTop: "24px" }}>
            <Form.Item
              name="reason"
              label="Reason for Budget Version:"
              rules={[
                {
                  required: true,
                  message: "Please input a reason",
                },
              ]}
              style={{ flexDirection: "column", alignItems: "flex-start" }}
              required
            >
              <TextArea
                data-testid="amendment-textarea"
                placeholder="Why was this amendment created?"
                style={{ width: "512px", resize: "none" }}
                rows={4}
              />
            </Form.Item>
          </Row>
        )}
      </Form>
    </Card>
  );
};
